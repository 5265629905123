import { Routes } from '@angular/router';

import { ensureSession } from './auth/ensure-session.guard';
import { userExistsGuard } from './auth/user-exists.guard';
import { MainPageComponent } from './main-page/main-page.component';

export const routesWithOverviewButton = ['/', '/create-account'];

export default [
    {
        path: 'create-account',
        canActivate: [ensureSession],
        title: 'Registreren',
        loadComponent: () => import('./create-account/create-account.component'),
    },
    {
        path: 'order-finished',
        title: 'Bestelling voltooid',
        loadComponent: () => import('./buy-voucher/order-confirmed/order-confirmed.component'),
    },
    {
        path: '',
        canActivate: [userExistsGuard],
        children: [
            { path: '', component: MainPageComponent },
            {
                path: 'wallet',
                title: 'Mijn Portefeuille',
                loadComponent: () => import('./wallet/wallet.component'),
            },
            {
                path: 'wallet/:campaignId',
                title: 'Mijn Portefeuille',
                loadChildren: () => import('./campaign-detail/campaign-detail.routes'),
            },
            {
                path: 'add-voucher',
                title: 'Bon Toevoegen',
                loadComponent: () => import('./add-voucher/add-voucher.component'),
            },
            { path: 'history', title: 'Historiek', loadComponent: () => import('./history/history.component') },
            {
                path: 'history/orders',
                title: 'Bestellingen',
                loadComponent: () => import('./orders/orders.component'),
            },
            {
                path: 'history/orders/:orderId',
                title: 'Bestelling',
                loadComponent: () => import('./orders/order-details/order-details.component'),
            },
            {
                path: 'history/transactions',
                title: 'Transacties',
                loadComponent: () => import('./transactions/transactions.component'),
            },
            {
                path: 'history/transactions/:transactionId',
                title: 'Transactie',
                loadComponent: () => import('./transactions/transaction-details/transaction-details.component'),
            },
            {
                path: 'buy',
                title: 'Bonnen kopen',
                loadComponent: () => import('./buy-voucher/buy-voucher.component'),
            },
        ],
    },
] satisfies Routes;
