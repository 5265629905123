import { ChangeDetectionStrategy, Component, computed, effect, inject, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { IonApp, IonRouterOutlet, ModalController, NavController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { filter, map } from 'rxjs';

import { environment } from '../environments/environment';
import { selectCurrentUser } from './brainlane-vouchers.selectors';
import { routesWithOverviewButton } from './routes';

@Component({
    selector: 'bv-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: '<ion-app><ion-router-outlet /></ion-app>',
    imports: [IonApp, IonRouterOutlet],
})
export class BrainlaneVouchersAppComponent {
    private routerOutlet = viewChild.required(IonRouterOutlet);
    private currentUser = inject(Store).selectSignal(selectCurrentUser);
    private modalController = inject(ModalController);
    private navController = inject(NavController);
    private router = inject(Router);
    private currentUrl = toSignal(
        this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
            map((e) => e.url),
        ),
    );
    backButtonLabel = computed(() =>
        routesWithOverviewButton.includes(this.currentUrl() ?? '') ? 'Overzicht' : 'Terug',
    );

    constructor() {
        effect(() => environment.mbpClient.ui.setBacklinkLabel(this.backButtonLabel()));
        environment.mbpClient.navigation.onBackNavigation((): boolean => {
            if (this.routerOutlet().canGoBack()) {
                this.modalController.getTop().then((modal) => {
                    if (modal) {
                        modal.dismiss();
                    } else {
                        this.navController.pop();
                    }
                });
                return false;
            }
            return true;
        });
        effect(() => {
            const user = this.currentUser();
            Sentry.setUser(
                user
                    ? {
                          id: user.id,
                          email: user.email,
                          username: `${user.firstName} ${user.lastName}`,
                      }
                    : null,
            );
        });
    }
}
